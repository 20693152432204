import { elementEditorModes } from "../../../enums/edit/elementEditorModes";
import { animationTypes } from "../../../enums/animations/animationTypes";
import { textSizeOptions } from "../../../enums/edit/textSizes";
import { textPos } from "../../../enums/edit/textPos";
import { lazy, ReactNode, Suspense } from "react";
import { imageUploadFormats } from "../../../enums/edit/imageUploadFormats";
import { googleMapFormats } from "../../../enums/edit/googleMapFormats";
import { videoFormats } from "../../../enums/edit/videoFormats";
import { supportedVideoSites } from "../../../enums/edit/supportedVideoSites";
import { animationInterface } from "../../../interfaces/animations/animationInterface";
import { fixedCellCount } from "../../../enums/edit/boxCells";
import { gridStil } from "../../../enums/edit/grid/gridStil";
import { gridWidths } from "../../../enums/edit/grid/gridWidths";
import { gridPosX, gridPosY } from "../../../enums/edit/grid/gridMediaPos";
import { heightOptions } from "../../../enums/edit/grid/heightOptions";
import {
  specialFunctionInterface,
  specialFunctionsDesignEnum,
  specialFunctionsTypeEnum,
} from "../../htmlElements/link/specialFunction";
import { useElementAddCache } from "../../../stateManagement/edit/useElementAddCache";
import { textInterface } from "../../../interfaces/elements/textInterface";
import { pictureInterface } from "../../../interfaces/elements/pictureInterface";
import { svgObjectInterface } from "../../../interfaces/elements/svgObjectInterface";
import { pdfObjectInterface } from "../../../interfaces/elements/pdfObjectInterface";
import { videoInterface } from "../../../interfaces/elements/videoInterface";
import { internalLinkInterface } from "../../../interfaces/elements/internalLinkInterface";
import { externalLinkInterface } from "../../../interfaces/elements/externalLinkInterface";
import { boxInterface } from "../../../interfaces/elements/boxInterface";
import { uListInterface } from "../../../interfaces/elements/uListInterface";
import { oListInterface } from "../../../interfaces/elements/oListInterface";
import { paddingOptions } from "../../../enums/edit/paddingOptions";
import SocialLinkEdit, { socialLinkInterface } from "./socialLinkEdit";
import { socialMediaTypes } from "../../../../backend/enums/database/kunden/socialMedia/socialMediaTypes";
import { removeSpecialChars } from "../../../functions/generall/removeSpecialChars";
import { CustomElementEdit } from "./customElementEdit";
import StaticButtons from "./user/staticButtons";

const TextEdit = lazy(
  () => import(/* webpackChunkName: "textEdit" */ "./textEdit"),
);

const ImageEdit = lazy(
  () => import(/* webpackChunkName: "imageEdit" */ "./media/imageEdit"),
);

const GoogleMapEdit = lazy(
  () => import(/* webpackChunkName: "googleMapEdit" */ "./googleMapEdit"),
);

const SvgObjectEdit = lazy(
  () => import(/* webpackChunkName: "svgObjectEdit" */ "./media/svgObjectEdit"),
);

const PdfObjectEdit = lazy(
  () => import(/* webpackChunkName: "pdfObjectEdit" */ "./media/pdfObjectEdit"),
);

const VideoEdit = lazy(
  () => import(/* webpackChunkName: "videoEdit" */ "./media/videoEdit"),
);

const InternalLinkEdit = lazy(
  () => import(/* webpackChunkName: "internalLinkEdit" */ "./internalLinkEdit"),
);

const ExternalLinkEdit = lazy(
  () => import(/* webpackChunkName: "externalLinkEdit" */ "./externalLinkEdit"),
);

const BoxEdit = lazy(
  () => import(/* webpackChunkName: "boxEdit" */ "./boxEdit"),
);

const UListEditor = lazy(
  () => import(/* webpackChunkName: "uListEditor" */ "./uListEditor"),
);

const OListEditor = lazy(
  () => import(/* webpackChunkName: "oListEditor" */ "./oListEditor"),
);

const GridEditor = lazy(
  () => import(/* webpackChunkName: "gridEditor" */ "./gridEditor"),
);

const SpecialFunctionEdit = lazy(
  () =>
    import(
      /* webpackChunkName: "specialFunctionEdit" */ "./specialFunctionEdit"
    ),
);

const OnPageForms = lazy(
  () => import(/* webpackChunkName: "onPageForms" */ "./onPageForms"),
);

export const emptyAnimations: animationInterface[] = [
  {
    animation: "none",
    type: animationTypes.start,
    behavior: "none",
    time: 1000,
  },
  {
    animation: "none",
    type: animationTypes.start,
    behavior: "none",
    time: 1000,
  },
  {
    animation: "none",
    type: animationTypes.start,
    behavior: "none",
    time: 1000,
  },
];

export default function AddElementConfig(props: {
  element: string;
  handleElementAdd: (elementData: object) => void;
  handleElementWithFileAdd: (
    elementData: object,
    file: Blob,
    filesDimensions?: object,
  ) => void;
  handleGridElementAdd: (
    elementData: object,
    file: Blob,
    fileType: "image" | "video" | "none",
    filesDimensions?: object,
    svgFiles?: { blob: Blob; name: string }[],
  ) => void;
  handleClosing: () => void;
  elementData?: object | null;
}) {
  let element: ReactNode;
  const elementAddingCache = useElementAddCache();
  //setup empty element for each element type
  switch (props.element) {
    case "H1":
    case "H2":
    case "H3":
    case "Text":
    case "Slogan":
    case "Zitat":
      element = (
        <TextEdit
          elementData={
            (elementAddingCache.cache[
              props.element
            ] as unknown as textInterface)
              ? {
                  ...(elementAddingCache.cache[
                    props.element
                  ] as unknown as textInterface),
                  text: "",
                  editorText: "",
                }
              : {
                  element: props.element,
                  text:
                    props.elementData !== null
                      ? typeof props.elementData["clipboard"] !== "undefined"
                        ? props.elementData["clipboard"] !== null
                          ? (props.elementData["clipboard"] as string)
                          : ""
                        : ""
                      : "",
                  editorText:
                    props.elementData !== null
                      ? typeof props.elementData["clipboard"] !== "undefined"
                        ? props.elementData["clipboard"] !== null
                          ? (props.elementData["clipboard"] as string)
                          : ""
                        : ""
                      : "",
                  sizeAndPos: {
                    size: textSizeOptions.standard,
                    pos: textPos.left,
                  },
                  isLive: true,
                  animations: emptyAnimations,
                  padding: paddingOptions.paddingboth,
                }
          }
          mode={elementEditorModes.add}
          handleElementSaving={props.handleElementAdd}
          handleClosing={props.handleClosing}
        />
      );
      break;
    case "Image":
      element = (
        <ImageEdit
          elementData={
            (elementAddingCache.cache[
              props.element
            ] as unknown as pictureInterface)
              ? {
                  ...(elementAddingCache.cache[
                    props.element
                  ] as unknown as pictureInterface),
                  originalFile:
                    props.elementData !== null
                      ? (props.elementData["file"] as string)
                      : "",
                  file:
                    props.elementData !== null
                      ? (props.elementData["file"] as string)
                      : "",
                  fileName: "",
                }
              : {
                  function: {
                    name: "none",
                    param: "",
                  },
                  cacheString: "",
                  alt: document.querySelector('meta[name="description"]')[
                    "content"
                  ] as string,
                  format: imageUploadFormats.original,
                  pos: "",
                  radius: "radstandard",
                  title: window.document.title,
                  fileName: "",
                  file:
                    props.elementData !== null
                      ? (props.elementData["file"] as string)
                      : "",
                  originalFile:
                    props.elementData !== null
                      ? (props.elementData["file"] as string)
                      : "",
                  width: {
                    value: 100,
                    unit: "%",
                  },
                  element: props.element,
                  imageSize: {
                    big: {
                      width: 0,
                      height: 0,
                    },
                    small: {
                      width: 0,
                      height: 0,
                    },
                    medium: {
                      width: 0,
                      height: 0,
                    },
                  },
                  isLive: true,
                  animations: emptyAnimations,
                }
          }
          mode={elementEditorModes.add}
          handleElementSaving={props.handleElementWithFileAdd}
          handleClosing={props.handleClosing}
        />
      );
      break;
    case "GoogleMap":
      element = (
        <GoogleMapEdit
          elementData={{
            function: "none",
            width: 400,
            height: 400,
            radius: "radstandard",
            title: "",
            zoomLevel: 17,
            mapType: "roadmap",
            address: "",
            format: googleMapFormats.quadrat,
            animations: emptyAnimations,
            element: props.element,
            isLive: true,
          }}
          mode={elementEditorModes.add}
          handleElementSaving={props.handleElementAdd}
          handleClosing={props.handleClosing}
        />
      );
      break;
    case "SvgObject":
      element = (
        <SvgObjectEdit
          elementData={
            (elementAddingCache.cache[
              props.element
            ] as unknown as svgObjectInterface)
              ? {
                  ...(elementAddingCache.cache[
                    props.element
                  ] as unknown as svgObjectInterface),
                  fileName:
                    props.elementData !== null
                      ? removeSpecialChars(
                          props.elementData["fileName"] as string,
                        )
                      : "",
                  file:
                    props.elementData !== null
                      ? (props.elementData["file"] as string)
                      : "",
                  title: removeSpecialChars(window.document.title),
                }
              : {
                  function: {
                    name: "none",
                    param: "",
                  },
                  imageSize: {
                    width: 0,
                    height: 0,
                  },
                  padding: paddingOptions.paddingboth,
                  cacheString: "",
                  fileName:
                    props.elementData !== null
                      ? removeSpecialChars(
                          props.elementData["fileName"] as string,
                        )
                      : "",
                  file:
                    props.elementData !== null
                      ? (props.elementData["file"] as string)
                      : "",
                  isLive: true,
                  title: removeSpecialChars(window.document.title),
                  shadow: "",
                  animations: emptyAnimations,
                  element: props.element,
                  pos: "posleft",
                  width: {
                    value: 100,
                    unit: "%",
                  },
                }
          }
          mode={elementEditorModes.add}
          handleElementSaving={props.handleElementWithFileAdd}
          handleClosing={props.handleClosing}
        />
      );
      break;
    case "PdfObject":
      element = (
        <PdfObjectEdit
          elementData={
            (elementAddingCache.cache[
              props.element
            ] as unknown as pdfObjectInterface)
              ? {
                  ...(elementAddingCache.cache[
                    props.element
                  ] as unknown as pdfObjectInterface),
                  title:
                    props.elementData !== null
                      ? (props.elementData["fileName"] as string)
                      : "",
                  fileTitle: "",
                  file:
                    props.elementData !== null
                      ? (props.elementData["file"] as string)
                      : "",
                }
              : {
                  title:
                    props.elementData !== null
                      ? (props.elementData["fileName"] as string)
                      : "",
                  fileTitle: "",
                  file:
                    props.elementData !== null
                      ? (props.elementData["file"] as string)
                      : "",
                  isLive: true,
                  animations: emptyAnimations,
                  element: props.element,
                  stil: "",
                  pos: "left",
                }
          }
          mode={elementEditorModes.add}
          handleElementSaving={props.handleElementWithFileAdd}
          handleClosing={props.handleClosing}
        />
      );
      break;
    case "Video":
      element = (
        <VideoEdit
          elementData={
            (elementAddingCache.cache[
              props.element
            ] as unknown as videoInterface)
              ? {
                  ...(elementAddingCache.cache[
                    props.element
                  ] as unknown as videoInterface),
                  embeddedData: {
                    url: "",
                    videoSite: supportedVideoSites.youtube,
                  },
                  uploadData: {
                    video: "",
                    videoName: "",
                    controls: false,
                    function: {
                      name: "none",
                      param: "",
                    },
                  },
                }
              : {
                  embeddedData: {
                    url: "",
                    videoSite: supportedVideoSites.youtube,
                  },
                  format: videoFormats["16:9"],
                  radius: "radstandard",
                  uploadData: {
                    video: "",
                    videoName: "",
                    controls: false,
                    function: {
                      name: "none",
                      param: "",
                    },
                  },
                  isLive: true,
                  element: props.element,
                  animations: emptyAnimations,
                  mode: "embed",
                }
          }
          mode={elementEditorModes.add}
          handleElementSaving={props.handleElementWithFileAdd}
          handleClosing={props.handleClosing}
        />
      );
      break;
    case "InternalLink":
      element = (
        <InternalLinkEdit
          elementData={
            (elementAddingCache.cache[
              props.element
            ] as unknown as internalLinkInterface)
              ? {
                  ...(elementAddingCache.cache[
                    props.element
                  ] as unknown as internalLinkInterface),
                  target: 1,
                }
              : {
                  element: props.element,
                  isLive: true,
                  animations: emptyAnimations,
                  pos: "posleft",
                  stil: "",
                  target: 1,
                }
          }
          mode={elementEditorModes.add}
          handleElementSaving={props.handleElementAdd}
          handleClosing={props.handleClosing}
        />
      );
      break;
    case "ExternalLink":
      element = (
        <ExternalLinkEdit
          elementData={
            (elementAddingCache.cache[
              props.element
            ] as unknown as externalLinkInterface)
              ? {
                  ...(elementAddingCache.cache[
                    props.element
                  ] as unknown as externalLinkInterface),
                  link: "",
                  title: "",
                }
              : {
                  element: props.element,
                  isLive: true,
                  link: "",
                  stil: "",
                  pos: "posleft",
                  animations: emptyAnimations,
                  title: "",
                }
          }
          mode={elementEditorModes.add}
          handleElementSaving={props.handleElementAdd}
          handleClosing={props.handleClosing}
        />
      );
      break;
    case "Box":
      element = (
        <BoxEdit
          elementData={
            (elementAddingCache.cache[props.element] as unknown as boxInterface)
              ? {
                  ...(elementAddingCache.cache[
                    props.element
                  ] as unknown as boxInterface),
                  children: [],
                }
              : {
                  element: props.element,
                  isLive: true,
                  cellCount: fixedCellCount["Box mit 1 Zelle"],
                  pos: "start",
                  stil: "bs0",
                  padding: "",
                  animations: emptyAnimations,
                  children: [],
                }
          }
          mode={elementEditorModes.add}
          handleElementSaving={props.handleElementAdd}
          handleClosing={props.handleClosing}
        />
      );
      break;
    case "UList":
      element = (
        <UListEditor
          elementData={
            (elementAddingCache.cache[
              props.element
            ] as unknown as uListInterface)
              ? {
                  ...(elementAddingCache.cache[
                    props.element
                  ] as unknown as uListInterface),
                  text: "",
                  editorText: "",
                }
              : {
                  element: props.element,
                  isLive: true,
                  text: "",
                  editorText: "",
                  sizeAndPos: {
                    size: textSizeOptions.standard,
                    pos: textPos.left,
                  },
                  padding: paddingOptions.paddingboth,
                  animations: emptyAnimations,
                  listItems: [],
                }
          }
          mode={elementEditorModes.add}
          handleElementSaving={props.handleElementAdd}
          handleClosing={props.handleClosing}
        />
      );
      break;
    case "OList":
      element = (
        <OListEditor
          elementData={
            (elementAddingCache.cache[
              props.element
            ] as unknown as oListInterface)
              ? {
                  ...(elementAddingCache.cache[
                    props.element
                  ] as unknown as oListInterface),
                  text: "",
                  editorText: "",
                }
              : {
                  element: props.element,
                  isLive: true,
                  text: "",
                  editorText: "",
                  sizeAndPos: {
                    size: textSizeOptions.standard,
                    pos: textPos.left,
                  },
                  padding: paddingOptions.paddingboth,
                  animations: emptyAnimations,
                  listItems: [],
                }
          }
          mode={elementEditorModes.add}
          handleElementSaving={props.handleElementAdd}
          handleClosing={props.handleClosing}
        />
      );
      break;
    case "GridDiv":
      element = (
        <GridEditor
          elementData={{
            element: props.element,
            isLive: true,
            gridVisible: true,
            gridStil: Object.values(gridStil)[0],
            gridWidth: Object.values(gridWidths)[0],
            image: "",
            imageName: "",
            contentPosition: "center",
            video: "",
            videoName: "",
            svgImages: [],
            posY: Object.values(gridPosY)[0],
            posX: Object.values(gridPosX)[0],
            filter: "",
            minHeight: Object.values(heightOptions)[0],
            originalFile: "",
            imageSize: {
              big: {
                width: 0,
                height: 0,
              },
              large: {
                width: 0,
                height: 0,
              },
              medium: {
                width: 0,
                height: 0,
              },
            },
          }}
          mode={elementEditorModes.add}
          handleElementSaving={props.handleGridElementAdd}
          handleClosing={props.handleClosing}
        />
      );
      break;
    case "SpecialFunction":
      element = (
        <SpecialFunctionEdit
          elementData={
            (elementAddingCache.cache[
              props.element
            ] as unknown as specialFunctionInterface)
              ? {
                  ...(elementAddingCache.cache[
                    props.element
                  ] as unknown as specialFunctionInterface),
                }
              : {
                  element: props.element,
                  type: specialFunctionsTypeEnum.contactform,
                  design: specialFunctionsDesignEnum.text,
                  text: "",
                  customFormNumber: 0,
                  animations: emptyAnimations,
                  stil: "",
                  pos: "posleft",
                  isLive: true,
                }
          }
          mode={elementEditorModes.add}
          handleElementSaving={props.handleElementAdd}
          handleClosing={props.handleClosing}
        />
      );
      break;
    case "SocialLink":
      element = (
        <SocialLinkEdit
          elementData={
            (elementAddingCache.cache[
              props.element
            ] as unknown as socialLinkInterface)
              ? {
                  ...(elementAddingCache.cache[
                    props.element
                  ] as unknown as socialLinkInterface),
                }
              : {
                  pos: "posleft",
                  stil: "",
                  animations: emptyAnimations,
                  type: socialMediaTypes.facebook,
                  isLive: true,
                  element: props.element,
                }
          }
          mode={elementEditorModes.add}
          handleElementSaving={props.handleElementAdd}
          handleClosing={props.handleClosing}
        />
      );
      break;
    case "OnPageForm":
      element = (
        <OnPageForms
          elementData={{
            formId: 0,
            formContent: [{}],
            name: "",
            element: props.element,
            isLive: true,
            color: "",
          }}
          mode={elementEditorModes.add}
          handleClosing={props.handleClosing}
          handleElementSaving={props.handleElementAdd}
        />
      );
      break;
    case "customElement":
      element = (
        <CustomElementEdit
          elementData={{
            element: props.element,
            isLive: true,
            customElementData: {
              contents: [],
              id: 0,
              name: "",
            },
          }}
          mode={elementEditorModes.add}
          handleClosing={props.handleClosing}
          handleElementSaving={props.handleElementAdd}
        />
      );
      break;
    case "UserLoginButton":
      element = (
        <StaticButtons
          elementData={{
            element: props.element,
            stil: "",
            pos: "posleft",
            animations: emptyAnimations,
            isLive: true,
          }}
          mode={elementEditorModes.add}
          handleClosing={props.handleClosing}
          handleElementSaving={props.handleElementAdd}
        />
      );
      break;
    case "UserRegisterButton":
      element = (
        <StaticButtons
          elementData={{
            element: props.element,
            stil: "",
            pos: "posleft",
            animations: emptyAnimations,
            isLive: true,
          }}
          mode={elementEditorModes.add}
          handleClosing={props.handleClosing}
          handleElementSaving={props.handleElementAdd}
        />
      );
      break;
  }

  return <Suspense fallback={null}>{element}</Suspense>;
}
